/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/reveal.js@4.1.3/dist/reveal.min.js
 * - /npm/reveal.js@4.1.3/plugin/markdown/markdown.min.js
 * - /npm/reveal.js@4.1.3/plugin/math/math.min.js
 * - /npm/reveal.js@4.1.3/plugin/highlight/highlight.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
